import React from "react"

import Layout from "../../components/layout-static"
import Seo from "../../components/seo"

import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Nursing Home Neglect Lawyers | Bartlett & Grippe",
    description:
      "Seeking justice for nursing home neglect? Bartlett & Grippe, LLC can help. Our experienced team navigates legal complexities to maximize compensation for loved one's injuries or death. Contact us.",
    heroH1: "Nursing Home Neglect",
    heroH2: "When experience, integrity, and results matter.",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2>Justice and Compensation for Nursing Home Errors or Neglect</h2>
            <h3>Did your loved one die due to the fault of a nursing home?</h3>
            <p className="mb-8">
              Claims of nursing home neglect or error combine legal complexities
              with emotionally charged matters. Clients come to Bartlett &
              Grippe, LLC seeking justice, and accountability. That type of
              justice is often obtained by maximizing compensation for injuries
              and death suffered by an elderly or disabled loved one.
            </p>
            <h3>
              Neglect in Connecticut nursing homes leading to death can take
              various forms, including but not limited to:
            </h3>
            <ul className="list-disc list-outside ml-6 mb-8">
              <li>Failure to provide proper nutrition and hydration</li>
              <li>
                Over-medicating or under-medicating patients (including
                medication errors)
              </li>
              <li>
                Failure to take precautions that prevent falls, wandering or
                elopement (Leaving the premises)
              </li>
              <li>Failure to provide a safe environment</li>
              <li>
                Failure to protect against physical abuse by staff or another
                resident.
              </li>
              <li>Covid 19</li>
            </ul>

            <h2>
              Did your loved one die at a Connecticut nursing facility due to
              improper care or neglect?
            </h2>
            <p className="mb-8">
              Bartlett & Grippe understands the personal issues involved when a
              loved one dies due to negligence or error while residing in a
              nursing home facility. At the initial consultation, our lawyers
              listen to your concerns and pinpoint key facts that are essential
              in building a claim against the nursing home. We have a medical
              expert on staff that scrutinizes the charts and records from the
              long-term care or nursing facility. Wrongful death is one of our
              firm’s core personal injury practice areas. We help families in
              all towns in Connecticut and take referrals from across the
              country.
            </p>
            <p className="mb-8">
              <Link to="/ct-wrongful-death/">
                For more information about wrongful death please click 15
                wrongful death questions
              </Link>
            </p>

            <p className="mb-8">
              Our goal is to be the best Connecticut nursing home neglect law
              firm and to exceed your every expectation.
            </p>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
